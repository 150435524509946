import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { DateTime } from 'luxon';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import useToken from '../../hooks/useToken';
import useFilter from '../../hooks/useFilter';
import { SearchIcon, EditIcon, PlusIcon } from '../../components/Icons';

import SearchField from '../../components/SearchField';

const GET_POSTS = gql`
  query GetPosts {
    getPosts {
      id
      enabled
      category {
        id
        name
        description
      }
      title
      body
      created_by {
        id
        first_name
        last_name
      }
      images {
        id
      }
      created
    }
  }
`;

export default function Posts() {
  const posts = useQuery(GET_POSTS, {
    fetchPolicy: 'no-cache',
  });
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const { isTokenExpired, deleteToken, hasPermission, getUserId } = useToken();
  const { filter } = useFilter();

  if (isTokenExpired()) {
    deleteToken();
  }

  useEffect(() => {
    if (posts.data) {
      // Remove disabled posts
      let enabledPosts = posts.data.getPosts.filter((x) => x.enabled);

      let filteredData = filter(enabledPosts, searchValue, ['title']);

      setFilteredData(filteredData);
    }
  }, [searchValue, posts.data]);

  if (posts.loading) return <h3>Loading...</h3>;

  const columns = [
    // {
    //   name: 'Topic',
    //   selector: (row) => row.category.name,
    //   sortable: true,
    // },
    {
      name: 'Title',
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: 'Author',
      selector: (row) =>
        `${row.created_by.first_name} ${row.created_by.last_name}`,
      sortable: true,
    },
    {
      name: 'Created Date',
      selector: (row) => row.created,
      sortable: true,
      format: (row, index) => {
        // Parse UTC value and convert to EDT
        let date = DateTime.fromMillis(parseInt(row.created)).toUTC();

        return date.toFormat('MM/dd/yyyy');
      },
      width: '150px',
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <Link to={`/posts/${row.id}/view`} className="btn btn-link">
            <SearchIcon /> View
          </Link>

          {(getUserId() === row.created_by.id || hasPermission('isadmin')) && (
            <Link to={`/posts/${row.id}`} className="btn btn-link">
              <EditIcon /> Edit
            </Link>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <SearchField value={searchValue} setValue={setSearchValue} />
        </div>

        <div className="col-md-6" style={{ textAlign: 'right' }}>
          <Link
            to="/posts/add"
            className="btn btn-outline-success btn-pill float-right"
            style={{ marginLeft: 'auto' }}
          >
            <PlusIcon /> Add Post
          </Link>
        </div>
      </div>

      <div className="card mt-2 mb-1">
        <DataTable data={filteredData} columns={columns} pagination striped />
      </div>
    </>
  );
}
