import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import useToken from '../hooks/useToken';
import { DateTime } from 'luxon';

const GET_POSTS = gql`
  query GetPosts {
    getPosts {
      id
      enabled
      category {
        id
        name
        description
      }
      title
      body
      created_by {
        id
        first_name
        last_name
      }
      images {
        id
      }
      created
    }
  }
`;

export default function Home(props) {
  const { isTokenExpired, deleteToken } = useToken();
  const posts = useQuery(GET_POSTS, {
    fetchPolicy: 'no-cache',
  });

  if (isTokenExpired()) {
    deleteToken();
  }

  let recentStories = [];

  if (posts.data !== undefined) {
    let enabledStories = posts.data.getPosts.filter((x) => x.enabled === true);

    let sortedStories = enabledStories.sort((a, b) => {
      return b.id - a.id;
    });

    let first5Stories = sortedStories.slice(0, 5);

    recentStories = first5Stories.map((post) => {
      return post;
    });
  }

  const postElement = recentStories.map((story) => {
    let date = DateTime.fromMillis(parseInt(story.created)).toUTC();

    return (
      <Link key={story.id} to={`/posts/${story.id}/view`}>
        <h4 style={{ marginBottom: '0px' }}>{story.title}</h4>
        <h5 style={{ fontWeight: 'normal', fontStyle: 'italic' }}>
          By {story.created_by.first_name} {story.created_by.last_name} on{' '}
          {date.toFormat('MM/dd/yyyy')}
        </h5>
      </Link>
    );
  });

  return (
    <div>
      <h1 className="subtitle">Quick Actions</h1>

      <div className="row">
        <div className="col">
          <Link className="btn btn-primary w-100" to="/deliveries/add">
            <i className="fas fa-plus" style={{ paddingRight: '.35em' }}></i>{' '}
            Add Delivery
          </Link>
        </div>
        <div className="col">
          <Link className="btn btn-light w-100" to="/deliveries">
            <i className="fas fa-truck" style={{ paddingRight: '.35em' }}></i>{' '}
            View Deliveries
          </Link>
        </div>
      </div>

      <div className="hr-text">News</div>

      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <h2>Giving Tuesday</h2>
              <b>Be sure to share your Giving Tuesday story!</b>
              <br />
              <br />
              <Link className="btn btn-success w-100" to="/posts/add">
                <i
                  className="fas fa-truck"
                  style={{ paddingRight: '.35em' }}
                ></i>{' '}
                Share your Story
              </Link>

              <br />
              <br />
              <h3>Recent Stories</h3>
              {recentStories.length === 0 && <p>No stories yet!</p>}
              {recentStories.length > 0 && <>{postElement}</>}
              <br />
              <p>
                <em>
                  <a href="/posts">See all stories</a>
                </em>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
