import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import md5 from 'md5';
import useToken from '../hooks/useToken';

export default function Navigation({
  deleteToken,
  showManagement,
  showPurchaseOrders,
  showReports,
}) {
  const [showNavigation, setShowNavigation] = useState(false);
  const { getDecodedToken } = useToken();

  const email = getDecodedToken().username;
  const firstName = getDecodedToken().firstName;
  const lastName = getDecodedToken().lastName;

  let avatar = `https://www.gravatar.com/avatar/`;

  if (email) {
    let emailHash = md5(email);
    avatar = `https://www.gravatar.com/avatar/${emailHash}`;
  }

  const handleLogout = async (e) => {
    e.preventDefault();

    deleteToken();
  };

  const handleToggleNavbar = async (e) => {
    setShowNavigation(!showNavigation);
  };

  return (
    <div>
      <header className="navbar navbar-expand-md navbar-dark d-print-none">
        <div className="container-xl">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-menu"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
            <Link to="/">
              <img
                src="/logo.svg"
                className="navbar-brand-image"
                alt="logo"
                width="112"
                height="28"
              />
            </Link>
          </h1>
          <div className="navbar-nav flex-row order-md-last">
            <div className="nav-item dropdown">
              <a
                href="#"
                className="nav-link d-flex lh-1 text-reset p-0"
                data-bs-toggle="dropdown"
                aria-label="Open user menu"
              >
                <span
                  className="avatar avatar-sm"
                  style={{
                    backgroundImage: `url(${avatar})`,
                  }}
                ></span>
                <div className="d-none d-xl-block ps-2">
                  <div>
                    {firstName} {lastName}
                  </div>
                </div>
              </a>
              <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                <a className="dropdown-item" onClick={handleLogout}>
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="navbar-expand-md">
        <div className="collapse navbar-collapse" id="navbar-menu">
          <div className="navbar navbar-light">
            <div className="container-xl">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <span className="nav-link">
                    <span className="nav-link-title">
                      <Link
                        to="/"
                        className="navbar-item"
                        style={{
                          textDecoration: 'none',
                          color: 'rgba(35, 46, 60, 0.7)',
                        }}
                        onClick={handleToggleNavbar}
                      >
                        Home
                      </Link>
                    </span>
                  </span>
                </li>

                {/* Deliveries */}
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#navbar-third"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    <span className="nav-link-title">Deliveries</span>
                  </a>
                  <div className="dropdown-menu">
                    <Link className="dropdown-item" to="/deliveries/add">
                      Add Delivery
                    </Link>
                    <Link className="dropdown-item" to="/deliveries">
                      View Deliveries
                    </Link>
                  </div>
                </li>

                {/* Purchase Orders */}
                {showPurchaseOrders && (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#navbar-third"
                      data-bs-toggle="dropdown"
                      role="button"
                      aria-expanded="false"
                    >
                      <span className="nav-link-title">Purchase Orders</span>
                    </a>
                    <div className="dropdown-menu">
                      <Link className="dropdown-item" to="/purchaseorders/add">
                        Add Purchase Order
                      </Link>
                      <Link className="dropdown-item" to="/purchaseorders">
                        View Purchase Orders
                      </Link>
                    </div>
                  </li>
                )}

                {/* Management */}
                {showManagement && (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#navbar-third"
                      data-bs-toggle="dropdown"
                      role="button"
                      aria-expanded="false"
                    >
                      <span className="nav-link-title">Management</span>
                    </a>
                    <div className="dropdown-menu">
                      <Link className="dropdown-item" to="/entities">
                        Entities
                      </Link>
                      <Link className="dropdown-item" to="/fuellocations">
                        Fuel Locations
                      </Link>
                      <Link className="dropdown-item" to="/payrollcycles">
                        Payroll Cycles
                      </Link>
                      <Link className="dropdown-item" to="/statements">
                        Statements
                      </Link>
                      <Link className="dropdown-item" to="/stations">
                        Stations
                      </Link>
                      <Link className="dropdown-item" to="/trucks">
                        Trucks
                      </Link>
                      <Link className="dropdown-item" to="/users">
                        Users
                      </Link>
                      <Link className="dropdown-item" to="/vendors">
                        Vendors
                      </Link>
                    </div>
                  </li>
                )}

                {/* Reports */}
                {showReports && (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#navbar-third"
                      data-bs-toggle="dropdown"
                      role="button"
                      aria-expanded="false"
                    >
                      <span className="nav-link-title">Reports</span>
                    </a>
                    <div className="dropdown-menu">
                      <Link className="dropdown-item" to="/reports/tabular">
                        Tabular Reports
                      </Link>
                    </div>
                  </li>
                )}

                {/* Posts */}
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#navbar-third"
                    data-bs-toggle="dropdown"
                    role="button"
                    aria-expanded="false"
                  >
                    <span className="nav-link-title">Posts</span>
                  </a>
                  <div className="dropdown-menu">
                    <Link className="dropdown-item" to="/posts/add">
                      Add Post
                    </Link>
                    <Link className="dropdown-item" to="/posts">
                      View Posts
                    </Link>
                  </div>
                </li>

                {/* Fuel Locations */}
                <li className="nav-item">
                  <span className="nav-link">
                    {/* <span className="nav-link-title"> */}
                      <Link
                        to="/fuel"
                        className="navbar-item"
                        style={{
                          textDecoration: 'none',
                          color: 'rgba(35, 46, 60, 0.7)',
                        }}
                      >
                        Fuel Locations
                      </Link>
                    {/* </span> */}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
